<script setup lang="ts">
import moment from 'moment'
import { capitalize } from 'vue'
import { toFormattedFixed } from '@core/utils/formatters'
import { paginationMeta } from '@/@api/utils/paginationMeta'
import ExchangesSelect from '@/components/selects/ExchangesSelect.vue'
import UsersSelect from '@/components/selects/UsersSelect.vue'
import { useCrud } from '@/pages/crud'
import UserDrawer from '@/views/admin/users/UserDrawer.vue'
import type { User, UserProperties } from '@api/handlers/user/types'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const user = ref()
const exchange = ref()
const status = ref()
const paymentStatus = ref()
const type = ref()
const page = ref(1)
const sortBy = ref('created_at')
const orderBy = ref('asc')
const searchQuery = ref('')
const itemsPerPage = ref(50)
const archived = ref(0)
const selectedInvoices = ref([])

const {
  data: responseData,
  execute: fetchInvoices,
  isFetching,
} = await useApi<PaginatedData<User>>(createUrl('/admin/invoices', {
  query: {
    q: searchQuery,
    per_page: itemsPerPage,
    user,
    exchange,
    status,
    paymentStatus,
    type,
    page,
    sortBy,
    orderBy,
    archived,
  },
}))

const invoiceTypes = {
  creation: 'Bot Creation',
  fund: 'Add Funds',
  extend: 'Extend Plan',
}

const crud = useCrud<User, UserProperties>('/admin/invoices', fetchInvoices)

const headers = [
  { title: '#', key: 'id' },
  { title: 'User', key: 'user_id' },
  { title: 'Exchange', key: 'exchange' },
  { title: 'Amount/Fee', key: 'amount' },
  { title: 'Pay Amount', key: 'pay_amount' },
  { title: 'Discount', key: 'discount' },
  { title: 'Type', key: 'type' },
  { title: 'Payment', key: 'payment_status' },
  { title: 'Status', key: 'status' },
  { title: 'Created', key: 'created_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const clearFilters = () => {
  exchange.value = ''
  user.value = ''
  type.value = ''
  paymentStatus.value = ''
  status.value = ''
}

const usersData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const loading = useLoading()
const confirm = useConfirm()

const paymentStatuses = [
  {
    title: 'Waiting',
    value: 'waiting',
  },
  {
    title: 'Failed',
    value: 'failed',
  },
  {
    title: 'Partially Paid',
    value: 'partially_paid',
  },
  {
    title: 'Finished',
    value: 'finished',
  },
]

const types = [
  {
    title: 'Bot Creation',
    value: 'creation',
  },
  {
    title: 'Add Funds',
    value: 'fund',
  },
  {
    title: 'Extend Plan',
    value: 'extend',
  },
]

const getTypeColor = (type: string) => {
  switch (type) {
    case 'creation':
      return 'success'
    case 'extend':
      return '#9575CD'
    case 'fund':
      return 'info'
  }
}

const getStatusColor = (_status: string) => {
  if (_status === 'paid')
    return 'success'
  else if (_status === 'processing')
    return 'warning'

  return 'error'
}

const getPaymentStatusColor = (_status: string) => {
  if (_status === 'finished')
    return 'success'
  else if (_status === 'waiting')
    return 'info'
  else if (_status === 'partially_paid')
    return 'warning'
  else if (_status === 'failed')
    return 'error'
}

const createConfirmedAction = (message: string | string[], cb: () => Promise<void>) => {
  return async () => {
    const result = await confirm.$show(message)
    if (!result)
      return

    loading.$show()

    await cb()

    loading.$hide()

    fetchInvoices()
  }
}

const acceptPayment = async (invoiceId: number) => {
  try {
    loading.$show()
    await $api(`/admin/invoices/${invoiceId}/accept`, {
      method: 'POST',
    })
    loading.$hide()
    fetchInvoices()
  }
  catch (e) {
    loading.$hide()
  }
}

const declinePayment = async (invoiceId: number) => {
  loading.$show()
  await $api(`/admin/invoices/${invoiceId}/decline`, {
    method: 'POST',
  })
  loading.$hide()
  fetchInvoices()
}

const restoreInvoice = async (invoiceId: number) => {
  const result = await confirm.$show('Are you sure you want to restore this invoice?')

  if (!result)
    return

  loading.$show()

  await $api(`/admin/invoices/${invoiceId}/restore`, {
    method: 'POST',
  })

  loading.$hide()

  fetchInvoices()
}

const archiveInvoice = async (invoiceId: number) => {
  const result = await confirm.$show('Are you sure you want to archive this invoice?')

  if (!result)
    return

  loading.$show()

  await $api(`/admin/invoices/${invoiceId}/archive`, {
    method: 'POST',
  })

  loading.$hide()
  fetchInvoices()
}

const bulkRestore = createConfirmedAction('Are you sure you want to restore the selected invoices', () => $api('/admin/invoices/bulk/restore', {
  method: 'POST',
  body: {
    ids: selectedInvoices.value.map(invoice => invoice.id),
  },
}))

const bulkArchive = createConfirmedAction('Are you sure you want to archive the selected invoices', () => $api('/admin/invoices/bulk/archive', {
  method: 'POST',
  body: {
    ids: selectedInvoices.value.map(invoice => invoice.id),
  },
}))

const bulkDelete = createConfirmedAction('Are you sure you want to delete the selected invoices', () => $api('/admin/invoices/bulk', {
  method: 'DELETE',
  body: {
    ids: selectedInvoices.value.map(invoice => invoice.id),
  },
}))

watch(archived, () => {
  selectedInvoices.value = []
})

watch(responseData, () => {
  selectedInvoices.value = []
})
</script>

<template>
  <section>
    <VCard class="mb-4">
      <VCardItem>
        <VCardTitle>
          Filters
        </VCardTitle>
        <template #append>
          <VBtn @click="clearFilters">
            Clear
          </VBtn>
        </template>
      </VCardItem>
      <VCardText>
        <VRow>
          <VCol cols="4">
            <ExchangesSelect v-model="exchange" />
          </VCol>
          <VCol cols="4">
            <UsersSelect v-model="user" />
          </VCol>
          <VCol cols="4">
            <VSelect
              v-model="type"
              label="Select Type"
              :items="types"
              clearable
            />
          </VCol>
          <VCol cols="4">
            <VSelect
              v-model="paymentStatus"
              label="Select Payment"
              :items="paymentStatuses"
              clearable
            />
          </VCol>
          <VCol cols="4">
            <VSelect
              v-model="status"
              label="Select Status"
              :items="['Processing', 'Cancelled', 'Declined', 'Paid']"
              clearable
            />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </section>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchInvoices"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: 999999, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VFadeTransition>
          <template v-if="selectedInvoices.length">
            <template v-if="archived">
              <VBtnGroup>
                <VBtn
                  color="success"
                  prepend-icon="tabler-restore"
                  @click="bulkRestore"
                >
                  Restore
                </VBtn>
                <VBtn
                  color="error"
                  prepend-icon="tabler-trash"
                  @click="bulkDelete"
                >
                  Delete
                </VBtn>
              </VBtnGroup>
            </template>
            <VBtn
              v-else
              color="error"
              prepend-icon="tabler-archive"
              @click="bulkArchive"
            >
              Archive
            </VBtn>
          </template>
        </VFadeTransition>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <VBtn
            :color="archived ? 'primary' : 'secondary'"
            prepend-icon="tabler-archive-filled"
            @click="archived = archived === 1 ? 0 : 1"
          >
            <template v-if="archived">
              Invoices
            </template>
            <template v-else>
              Archive
            </template>
          </VBtn>
          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model="selectedInvoices"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="usersData"
        :headers="headers"
        :items-length="total"
        :loading="isFetching"
        show-select
        return-object
        @update:options="updateOptions"
      >
        <template #item.user_id="{ item }">
          <VListItem
            class="pl-0"
            lines="two"
            :title="`${item.user.name}`"
            :subtitle="item.user.email"
          />
        </template>
        <template #item.email="{ item }">
          {{ item.user.email }}
        </template>
        <template #item.exchange="{ item }">
          <div class="d-flex align-center ga-2">
            <img
              :src="item.exchange.icon"
              :alt="item.exchange.name"
              width="25"
            >
            <span :style="`color: ${item.exchange.color}`">{{ item.exchange.name }}</span>
          </div>
        </template>
        <template #item.amount="{ item }">
          ${{ formatNumber(item.amount) }} / ${{ formatNumber(item.fee) }}
        </template>
        <template #item.pay_amount="{ item }">
          <template v-if="item.pay_amount">
            {{ toFormattedFixed(item.pay_amount) }} {{ item.pay_currency }}
          </template>
        </template>
        <template #item.payment_status="{ item }">
          <VChip :color="getPaymentStatusColor(item.payment_status)">
            {{ capitalize(item.payment_status) }}
          </VChip>
        </template>
        <template #item.status="{ item }">
          <VChip
            v-if="item.status"
            :color="getStatusColor(item.status)"
          >
            {{ capitalize(item.status) }}
          </VChip>
        </template>
        <template #item.type="{ item }">
          <VChip :color="getTypeColor(item.type)">
            {{ invoiceTypes[item.type] }}
          </VChip>
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- User -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <template v-if="item.status">
            <template v-if="item.deleted_at">
              <VTooltip text="Restore Invoice">
                <template #activator="{ props }">
                  <IconBtn
                    v-bind="props"
                    color="success"
                    @click="restoreInvoice(item.id)"
                  >
                    <VIcon icon="tabler-restore" />
                  </IconBtn>
                </template>
              </VTooltip>
            </template>
            <VTooltip
              v-else
              text="Archive Invoice"
            >
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="archiveInvoice(item.id)"
                >
                  <VIcon icon="tabler-archive" />
                </IconBtn>
              </template>
            </VTooltip>
          </template>
          <template v-if="!item.deleted_at && !item.status">
            <VTooltip text="Accept Payment">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="success"
                  @click="acceptPayment(item.id)"
                >
                  <VIcon icon="tabler-check" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Decline Payment">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="declinePayment(item.id)"
                >
                  <VIcon icon="tabler-x" />
                </IconBtn>
              </template>
            </VTooltip>
          </template>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <UserDrawer
    v-model:isDrawerOpen="crud.createComponent.value"
    :user="crud.model.value"
    @user-data="crud.drawerUpdate"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
