<script setup lang="ts">
import moment from 'moment'
import { paginationMeta } from '@/@api/utils/paginationMeta'
import { useCrud } from '@/pages/crud'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import UserDrawer from '@/views/admin/users/UserDrawer.vue'
import type { User } from '@api/handlers/user/types'
import PaymentAddressDialog from '@/views/admin/users/PaymentAddressDialog.vue'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const searchQuery = ref('')
const itemsPerPage = ref(999999)
const archived = ref(0)
const user = ref()

const { data: responseData, execute: fetchBlacklist, isFetching } = await useApi(createUrl('/admin/blacklist', {
  query: {
    q: searchQuery,
    per_page: itemsPerPage,
    page,
    sortBy,
    orderBy,
    archived,
  },
}))

const crud = useCrud('/admin/blacklist', fetchBlacklist)

const headers = [
  { title: 'Name', key: 'name' },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const refreshDrawer = () => {
  user.value = undefined
  fetchBlacklist()
}

const blackListData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)
const createConfirmAction = confirmedActionsCreator(fetchBlacklist)

const deleteAccount = createConfirmAction(
  (_, account) => `Are you sure you want to Disconnect ${account.exchange.name} Account?`,
  (_user: User, account) => $api(
    `/admin/users/${_user.id}/accounts/${account.id}`,
    {
      method: 'DELETE',
    },
  ),
)

const restoreUser = createConfirmAction(
  (_user: User) => `Are you sure you want to restore ${_user.name}`,
  (_user: User) => $api(
    `/admin/users/${_user.id}/restore`,
    {
      method: 'POST',
    },
  ),
)

const archiveUser = createConfirmAction(
  (_user: User) => `Are you sure you want to archive ${_user.name}`,
  (_user: User) => $api(
    `/admin/users/${_user.id}/archive`,
    {
      method: 'DELETE',
    },
  ),
)

const deleteUser = createConfirmAction(
  (_user: User) => `Are you sure you want to delete ${_user.name}`,
  (_user: User) => $api(
    `/admin/users/${_user.id}`,
    {
      method: 'DELETE',
    },
  ),
)

const paymentAddressDialogOpen = ref(false)

const openPaymentAddressDialog = (_user: User) => {
  user.value = _user
  paymentAddressDialogOpen.value = true
}
</script>

<template>
  <section>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchBlacklist"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: 999999, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <VBtn
            :color="archived ? 'primary' : 'secondary'"
            prepend-icon="tabler-archive-filled"
            @click="archived = archived === 1 ? 0 : 1"
          >
            <template v-if="archived">
              Users
            </template>
            <template v-else>
              Archive
            </template>
          </VBtn>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

          <!-- 👉 Add user button -->
          <VBtn
            prepend-icon="tabler-plus"
            @click="crud.create"
          >
            Blacklist Pair
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="blackListData"
        :headers="headers"
        :items-length="total"
        :loading="isFetching"
        @update:options="updateOptions"
      >
        <template #header.accounts>
          <span class="d-block w-100 text-center">Exchanges</span>
        </template>
        <template #item.name="{ item }">
          {{ item.name }}
        </template>
        <template #item.referral_id="{ item }">
          {{ item.parent?.name ?? '' }}
        </template>
        <template #item.accounts="{ item }">
          <VBtn
            v-for="account in item.accounts"
            :key="account.id"
            variant="plain"
            slim
            @click="deleteAccount(item, account)"
          >
            <img
              :src="account.exchange.icon"
              :alt="account.exchange.name"
              width="25"
            >
          </VBtn>
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <!-- User -->

        <!-- Actions -->
        <template #item.actions="{ item }">
          <VTooltip text="Payment Addresses">
            <template #activator="{ props }">
              <IconBtn
                v-bind="props"
                @click="openPaymentAddressDialog(item)"
              >
                <VIcon icon="tabler-address-book" />
              </IconBtn>
            </template>
          </VTooltip>
          <template v-if="item.deleted_at">
            <VTooltip text="Restore User">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="success"

                  @click="restoreUser(item)"
                >
                  <VIcon icon="tabler-restore" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Delete User">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="deleteUser(item)"
                >
                  <VIcon icon="tabler-trash" />
                </IconBtn>
              </template>
            </VTooltip>
          </template>
          <template v-else>
            <VTooltip text="Edit User">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  @click="crud.edit(item)"
                >
                  <VIcon icon="tabler-edit" />
                </IconBtn>
              </template>
            </VTooltip>
            <VTooltip text="Archive User">
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  color="error"
                  @click="archiveUser(item)"
                >
                  <VIcon icon="tabler-archive" />
                </IconBtn>
              </template>
            </VTooltip>
          </template>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <UserDrawer
    v-model="crud.createComponent.value"
    :user="crud.model.value"
    @update="refreshDrawer"
    @user-data="crud.drawerUpdate"
  />
  <!-- Dialog -->
  <ConfirmDialog
    v-model="crud.deleteConfirmation.value"
    content="Are you sure you want to delete this user?"
    @confirm="crud.destroy"
  />
  <PaymentAddressDialog
    v-model="paymentAddressDialogOpen"
    :user="user"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
